
.content-wrapper {
    max-width: 1200px;
}

.resume-content {
    background-color: black !important;
}

.resume {
    margin-bottom: 15px;
    justify-content: center;
}



@media (max-width: 600px) {
	.projects-container {
		padding-top: 80px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}

