.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 50px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
}

.bold-text {
	font-weight: 200;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}

	.homepage-education {
		margin-left: 30px;
	}
}

.education-img {
	max-width: 80px; /* Set the maximum width */
	max-height: 80px; /* Set the maximum height */
	width: auto; /* Allow the width to adjust based on the max-width and max-height */
	height: auto; /* Allow the height to adjust based on the max-width and max-height */
}

