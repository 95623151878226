.tech-icons .icon{
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    /* display: table !important; */
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    /* transition: all 0.4s ease 0s !important; */
  }

.tech-icons {
    text-align: center;
}


.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
}
